/* SidebarStyles.css */
.sidebar {
  position: fixed;
  top: 0;
  left: -250px; /* Set an initial value to hide the sidebar off-screen */
  width: 250px;
  height: 100%;
  overflow-x: hidden;

  background-color: #b3d1dd;
  color: white;
  padding: 20px;
  transition: left 0.3s ease-in-out;
  z-index: 1000; /* Set a higher z-index to ensure it overlays on top */
}

.sidebar.open {
  left: 0;
}

.close-button {
  top: 10px;
  left: 10px;
  font-size: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  float: right;
}

.close-button:hover {
  color: #ecf0f1; /* Hover color */
}

.dropdown {
  margin-bottom: 20px;
}

/* Style for dropdown buttons */
.dropdown .btn {
  width: 100%;
  text-align: left;
}

/* Style for dropdown menu items */
.dropdown-menu {
  background-color: #2c3e50; /* Dropdown menu background color */
}

.dropdown-item {
  color: white;
}

.dropdown-item:hover {
  background-color: #3498db; /* Hover color */
}

.img {
  width: 70%;
  float: left;
}

.custom-dropdown-menu {
  margin-right: -20px;
  text-align: center;
  font-weight: bold;
}
.logo{
  margin-left: 20px;
  width: 150px;
}

.logoo{
  width: 150px;

}
.close-button {
  position: relative;
}

.close-button:hover::before {
  content: "Close Sidebar";
  position: absolute;
  top: 110%;
  margin-left: -20px;
  transform: translateX(-50%);
  padding: 8px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0.9;
  z-index: 1;
  display: block;
  width: 100px;
}

/* Additional styling for better appearance */
.close-button::before {
  display: none;
  transition: opacity 0.3s ease-in-out;
}

.close-button:hover::before {
  display: block;
}