
  /* Booking Form Styles */
  .booking-form {
    width: 335px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	background-color: rgba(255, 255, 255, 0.9);
	/* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(8px); */
	border: 1px solid #ddd;
	padding: 25px;
	border-radius: 8px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	position: fixed;
	top: -60px; /* Adjust as needed to set the initial top position */
	left: 50%;	
	transform: translateX(-50%);
	z-index: 1000; /* Adjust as needed to ensure it appears above other elements */
	transition: bottom 0.3s ease-in-out; /* Add smooth transition effect */
  }
  
  .booking-form.expanded {
	bottom: 0;
  }
  
  .booking-form h3 {
	font-size: 24px;
	margin-bottom: 20px;
	color: #333;
	text-align: center;
	font-family: bold;
  }
  
  .check-date {
	margin-bottom: 20px;
	position: relative;

  }
  
  label {
	font-size: 18px;
	color: #333;
	display: block;
	margin-bottom: 10px;
  }
  input{
	height:50px;
	background: transparent;
  }
  .icon_calendar {
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
	font-size: 20px;
	color: #777;
  }
  .booking-form button {
	
	text-transform: uppercase;
	height: 50px;
	width: 100%;
	
}
  input[type="text"] {
	width: 100%;
	padding: 15px;
	border: 1px solid rgb(196, 190, 190);
	border-radius: 4px;
	font-size: 16px;
  }
  
  .select-option {
	margin-bottom: 20px;
  }
  
  .guests-container {
	display: none;
	margin-top: 10px;
  }
  
  .number-controls {
	display: flex;
	align-items: center;
  }
  
  .number-controls label {
	margin-right: 20px;
  }
  
  .b {
	background-color: #4caf50;
	color: #fff;
	border: none;
	padding: 8px;
	border-radius: 4px;
	cursor: pointer;
	font-size: 18px;
  }
  .guests-container {

	top: 100%;
	left: 0;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 8px;
	margin-top: 10px;
	display: inline-block;
	z-index: 1000; /* Adjust as needed to ensure it appears above other elements */
	bottom: 0; /* Ensure the bottom of the container is at the bottom of the form */

	
}

.number-controls {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.done-button{
	border-radius: 0px;
}

.guests-container button {
	display: block;
	font-size: 14px;
	text-transform: uppercase;
	border: 1px solid #dfa974;
	border-radius: 50px;
	color: #dfa974;
	font-weight: 500;
	background: transparent;
	width: 100%;
	height: 39px;
	margin-top: 8px;
}

.done-button:hover {
	background-color: #45a049;
	color: white;
}
  .booking-form.expanded {
	height: 300px; /* Set a height that suits your design */
  }
  .guests-container label{
margin-left: 10px;
  }
  .done-button {
	background-color: #4caf50;
	color: #fff;
	border: none;
	padding: 12px;
	border-radius: 4px;
	cursor: pointer;
	font-size: 16px;
	margin-top: 20px;
  }
  
  .btn-success {
	background-color: #4caf50;
	color: #fff;
	border: none;
	padding: 15px;
	border-radius: 4px;
	cursor: pointer;
	font-size: 18px;
  }
  
  .btn-success:hover {
	background-color: #45a049;
  }
  /* Add this CSS to your existing styles */

  .booking-form {
	/* ... (your existing styles) */
	position: absolute;
	width: 355px
  }
 
  .check-date {
	position: relative;
  }
  
  .calendar-popup {
	position: absolute;
	top: 100%;
	left: 10px;
	z-index: 1000; /* Adjust as needed to ensure it appears above other elements */
	background-color: #fff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
	border: 1px solid #ddd;
	border-radius: 8px;
	margin-top: 5px;
	display: inline-block;
  }
  
  .calendar-popup .react-calendar {
	border: none;
	box-shadow: none;
	font-size: 8px;
  }
  
  .calendar-popup .react-calendar__tile--active {
	background: #4caf50; /* Adjust the color as needed */
	color: #fff;
  }
  
  .calendar-popup .react-calendar__tile--now {
	background: #eee;
  }
  
  .calendar-popup .react-calendar__tile--hasActive {
	background: #f0f0f0;
  }
  
  .calendar-popup .react-calendar__tile--rangeStart,
  .calendar-popup .react-calendar__tile--rangeEnd,
  .calendar-popup .react-calendar__tile--selectStart,
  .calendar-popup .react-calendar__tile--selectEnd {
	background: #4caf50; /* Adjust the color as needed */
	color: #fff;
  }
  
  .calendar-popup .react-calendar__tile--selectRange {
	background: rgba(76, 175, 80, 0.5); /* Adjust the color and opacity as needed */
  }
  
  .calendar-popup .react-calendar__tile--rangeStart:enabled:hover,
  .calendar-popup .react-calendar__tile--rangeEnd:enabled:hover,
  .calendar-popup .react-calendar__tile--selectStart:enabled:hover,
  .calendar-popup .react-calendar__tile--selectEnd:enabled:hover {
	background: #45a049; /* Adjust the hover color as needed */
  }


  